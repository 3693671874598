import {useQuery} from '@tanstack/react-query';
import {getMyReviewQuery} from 'src/utils/queries';
import fetcher from 'src/utils/fetcher';

type Options = {
  countryCode: string | undefined;
  placeNameId: string | undefined;
  initialReview?: Review;
  session: IronSession | null;
};

const useMyReview = ({countryCode, placeNameId, initialReview, session}: Options) => {
  const reviewUserId = initialReview?.user?._id || initialReview?.user;
  const isMyReview = session && session?._id === reviewUserId;

  const {
    data: reviews = [],
    refetch: refetchMyReview,
    isLoading: isLoadingReview
  } = useQuery<Review[]>({
    queryKey: ['myPlaceReview', countryCode, placeNameId],
    queryFn: () => fetcher(getMyReviewQuery(countryCode || '', placeNameId || '', session?._id)),
    enabled: Boolean(session && countryCode && placeNameId),
    initialData: isMyReview && initialReview ? [initialReview] : undefined
  });

  const review = reviews[0] || (isMyReview ? initialReview : null);

  return {review, refetchMyReview, isLoadingReview};
};

export default useMyReview;
