type TraitRatingType = {
  type: 'people' | 'food' | 'spaces' | 'value' | 'safety';
  reviewKey: 'ratingPeople' | 'ratingFood' | 'ratingSpaces' | 'ratingValue' | 'ratingSafety';
  label: string;
};

const traitRatings: TraitRatingType[] = [
  {
    type: 'people',
    reviewKey: 'ratingPeople',
    label: 'People'
  },
  {
    type: 'food',
    reviewKey: 'ratingFood',
    label: 'Food'
  },
  {
    type: 'spaces',
    reviewKey: 'ratingSpaces',
    label: 'Spaces'
  },
  {
    type: 'value',
    reviewKey: 'ratingValue',
    label: 'Value'
  },
  {
    type: 'safety',
    reviewKey: 'ratingSafety',
    label: 'Safety'
  }
];

export default traitRatings;
