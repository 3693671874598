'use client';

import {Suspense, lazy} from 'react';
import {Slot} from '@radix-ui/react-slot';
import type {MyList} from 'server/apiHandlers/getMyListsHandler';
import recordEvent from 'src/utils/recordEvent';

const AuthModal = lazy(() => import('src/components/auth/AuthModal'));
const SavePlaceModal = lazy(() => import('./SavePlaceModal'));

type Props = {
  children: React.ReactNode;
  place: Partial<Place>;
  initialMyLists?: MyList[];
  session: IronSession | null;
};

const SavePlaceButtonWrapper = ({children, place, initialMyLists, session}: Props) => {
  if (!session)
    return (
      <Suspense fallback={children}>
        <AuthModal initialView="join">
          <Slot onClick={() => recordEvent('savePlace', {props: {place: place?.name, loggedIn: !!session}})}>
            {children}
          </Slot>
        </AuthModal>
      </Suspense>
    );

  return (
    <Suspense fallback={children}>
      <SavePlaceModal place={place} initialMyLists={initialMyLists} session={session}>
        {children}
      </SavePlaceModal>
    </Suspense>
  );
};

export default SavePlaceButtonWrapper;
