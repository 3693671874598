import chunk from 'lodash/chunk';
import {decodeBlurHash} from 'fast-blurhash';
import quantize from './quantize';

const getVibrance = (color: number[]): number => {
  const [r, g, b] = color;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  return max - min;
};

export const getShadowColorFromBlur = (blurHash: string | undefined): number[] => {
  if (!blurHash) return [0, 0, 0];

  const pixels = decodeBlurHash(blurHash, 12, 12);

  const colors = chunk(pixels, 4);
  const bottomColors = colors.slice(3 * 12);

  const colorMap = quantize(bottomColors, 5);
  const colorPalette = colorMap.palette();

  const vibrantColor = colorPalette.reduce((acc: number[], color: number[]) => {
    if (getVibrance(color) > getVibrance(acc)) return color;

    return acc;
  }, colorPalette[0]);

  return vibrantColor;
};
