import RemixIcon from './RemixIcon';

import people from 'public/icons/peopleIcon.svg';
import spaces from 'public/icons/spacesIcon.svg';
import safety from 'public/icons/safetyIcon.svg';
import value from 'public/icons/valueIcon.svg';
import food from 'public/icons/foodIcon.svg';

const icons = {people, spaces, value, safety, food} as any;

type Props = {
  style?: React.CSSProperties;
  type: 'people' | 'spaces' | 'safety' | 'value' | 'food';
  color?: string;
  size?: number;
};

const TraitIcon = ({type, style, color, size}: Props) => {
  return <RemixIcon icon={icons[type]} style={style} color={color} size={size} />;
};

export default TraitIcon;
