import {useEffect, useState} from 'react';
import {useRouter} from 'next/navigation';
import {useMutation} from '@tanstack/react-query';
import isProperPlace from 'src/utils/isProperPlace';
import fetcher from 'src/utils/fetcher';

type TraitRatingField = 'ratingFood' | 'ratingPeople' | 'ratingSpaces' | 'ratingSafety' | 'ratingValue';

type ModalSection = 'main' | 'traits' | 'review';

type Options = {
  place: Partial<Place> | PlacePageType;
  review: Review;
  refetchReview: () => void;
  controlledIsOpen?: boolean;
  controlledSetIsOpen?: (open: boolean) => void;
  onUpdated?: () => void;
};

const useReviewModal = ({review, refetchReview, place, controlledIsOpen, controlledSetIsOpen, onUpdated}: Options) => {
  const router = useRouter();
  const [localIsOpen, localSetIsOpenState] = useState(false);
  const [section, setSection] = useState<ModalSection>('main');

  const [reviewState, setReviewState] = useState<Partial<Review> | null>(review);

  const setIsOpen = (open: boolean) => {
    if (open && review && section === 'main') setSection('review');

    if (controlledSetIsOpen && open !== controlledIsOpen) {
      return controlledSetIsOpen(open);
    }

    if (open !== localIsOpen) return localSetIsOpenState(open);
  };

  const isOpen = controlledIsOpen !== undefined ? controlledIsOpen : localIsOpen;

  const showTraits = isProperPlace(place?.placeType || '');

  useEffect(() => {
    if (!reviewState && review) setReviewState(review);
  }, [review]);

  const {mutateAsync: upsertReview} = useMutation({
    mutationFn: (values: any) =>
      fetcher({
        url: '/api/reviews',
        options: {method: 'PUT'},
        body: {placeId: place?._id, ...values}
      }),
    onSuccess: () => {
      if (onUpdated) onUpdated();
      refetchReview();
      router.refresh();
    }
  });

  const updateReview = async (values: Partial<Review> | null, save?: boolean) => {
    const newReviewState = {...reviewState, ...values};

    setReviewState(newReviewState);

    if (save) return upsertReview(newReviewState);
  };

  const setMainRating = (rating: number) => {
    updateReview({mainRating: rating}, true);
    setSection(showTraits ? 'traits' : 'review');
  };

  const setTraitRating = (traitField: TraitRatingField, rating: number) => {
    updateReview({[traitField]: rating}, true);
  };

  const setReviewText = (text: string) => {
    updateReview({text});
  };

  const submitTextReview = () => {
    updateReview(reviewState, true);
  };

  const reset = () => {
    setSection('main');
    setReviewState(null);
    setIsOpen(false);
  };

  return {
    isOpen,
    setIsOpen,
    section,
    setSection,
    setMainRating,
    setTraitRating,
    reviewState,
    setReviewText,
    submitTextReview,
    reset
  };
};

export default useReviewModal;
