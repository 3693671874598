'use client';

import dynamic from 'next/dynamic';
import {Slot} from '@radix-ui/react-slot';
import recordEvent from 'src/utils/recordEvent';
import RatePlaceModal from './RatePlaceModal';
import useSession from 'src/hooks/useSession';

const AuthModal = dynamic(() => import('../auth/AuthModal'));

type Props = {
  children?: React.ReactNode;
  place: Partial<Place> | PlacePageType;
  initialReview?: Review;
  onReviewUpdated?: () => void;
  controlledIsOpen?: boolean;
  controlledSetIsOpen?: (open: boolean) => void;
};

const RatePlaceButtonWrapper = ({
  children,
  place,
  initialReview,
  onReviewUpdated,
  controlledIsOpen,
  controlledSetIsOpen
}: Props) => {
  const {session, isLoading} = useSession();

  if (isLoading) return children;

  if (!session)
    return (
      <AuthModal initialView="join">
        <Slot onClick={() => recordEvent('ratePlace', {props: {place: place.name, loggedIn: !!session}})}>
          {children}
        </Slot>
      </AuthModal>
    );

  return (
    <RatePlaceModal
      key={place._id}
      place={place}
      initialReview={initialReview}
      onUpdated={onReviewUpdated}
      session={session}
      controlledIsOpen={controlledIsOpen}
      controlledSetIsOpen={controlledSetIsOpen}
    >
      {children}
    </RatePlaceModal>
  );
};

export default RatePlaceButtonWrapper;
