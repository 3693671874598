import times from 'lodash/times';
import {twJoin, twMerge} from 'tailwind-merge';
import {ratingStar} from 'src/utils/icons';
import {ratingColors} from 'src/constants';
import BaseButton from '../BaseButton';
import RemixIcon from '../RemixIcon';

type Props = {
  onRate: (rating: number) => void;
  rating: number | undefined;
  starSize?: number;
  className?: string;
};

const RatingStars = ({onRate, rating, starSize = 40, className}: Props) => {
  const activeColor = rating ? ratingColors[rating - 1] : 'var(--empty-star-color)';

  return (
    <div
      className={twMerge(
        'flex [--empty-star-color:rgba(100,100,100,0.3)] dark:[--empty-star-color:rgba(150,150,150,0.4)]',
        className
      )}
      style={{gap: starSize / 6}}
    >
      {times(5, (i) => {
        const isActive = rating && rating >= i + 1;

        return (
          <BaseButton
            key={i}
            onPress={() => onRate(i + 1)}
            className={twJoin(
              'p-0 overflow-visible',
              isActive
                ? 'hover:brightness-[0.9] dark:hover:brightness-[1.2]'
                : 'hover:brightness-[0.6] dark:hover:brightness-[1.2]'
            )}
          >
            <RemixIcon icon={ratingStar} size={starSize} color={isActive ? activeColor : 'var(--empty-star-color)'} />
          </BaseButton>
        );
      })}
    </div>
  );
};

export default RatingStars;
