'use client';

import {useState} from 'react';
import {twJoin} from 'tailwind-merge';
import {infoI} from 'src/utils/icons';
import RemixIcon from '../RemixIcon';

const MapAttribution = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="absolute bottom-3 left-3 max-[700px]:bottom-2 max-[700px]:left-2 flex gap-1">
      <div className="bg-[rgba(255,255,255,0.6)] dark:bg-[rgba(0,0,0,0.2)] py-1 px-2 flex gap-2 text-xs text-[--dark-text] rounded-full">
        <a
          href="https://www.mapbox.com/about/maps/"
          target="_blank"
          rel="noreferrer nofollow external"
          className="dark:drop-shadow-[0_1px_0_rgb(0_0_0_/_0.5)]"
        >
          © Mapbox
        </a>
        <a
          href="http://www.openstreetmap.org/copyright"
          target="_blank"
          rel="noreferrer nofollow external"
          className={twJoin('dark:drop-shadow-[0_1px_0_rgb(0_0_0_/_0.5)]', !expanded && 'max-[700px]:hidden')}
        >
          © OpenStreetMap
        </a>
        <a
          href="https://www.mapbox.com/map-feedback/"
          target="_blank"
          rel="noreferrer nofollow external"
          className={twJoin('dark:drop-shadow-[0_1px_0_rgb(0_0_0_/_0.5)]', !expanded && 'max-[700px]:hidden')}
        >
          Improve this map
        </a>
      </div>

      <div
        className={twJoin(
          'bg-[rgba(255,255,255,0.6)] dark:bg-[rgba(0,0,0,0.2)] rounded-full',
          expanded ? 'hidden' : 'hidden max-[700px]:flex'
        )}
      >
        <button
          type="button"
          className="py-1 px-2 dark:drop-shadow-[0_1px_0_rgb(0_0_0_/_0.5)]"
          onClick={() => setExpanded(true)}
        >
          <RemixIcon icon={infoI} color="var(--dark-text)" size={14} />
        </button>
      </div>
    </div>
  );
};

export default MapAttribution;
